export const revalidate404: number = 90;
export const revalidatePage: number = 300;

export const isDev: boolean = process.env.NEXT_PUBLIC_ENVIRONMENT === 'development';
export const isStage: boolean = process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging';
export const isProd: boolean = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

export const protectedPath: string[] = [
  '/form-per-sales/',
]

export const appConfig = {
  siteName: 'Edison NEXT',
  locales: ['it', 'en'],
  defaultLocale: 'it',
}