import dayjs from "dayjs"

export const formatDate = (date, lang) => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    return null;
  }
  return parsedDate.toLocaleString(lang, {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
};

export const getHour = (date, lang) => {
  return new Date(date)
    .toLocaleString(lang, {
      'hour': '2-digit',
      'minute': '2-digit',
    }
  )
}

export function generateArrayOfYears(num = 10): number[] {
  let max:number = new Date().getFullYear()
  let min:number = max - num
  let years:number[] = []

  for (let i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export function generateArrayFromYears(num = 2010): number[] {
  let max:number = new Date().getFullYear()
  let years: number[] = []

  for (let i: number = max; i >= num; i--) {
    years.push(i)
  }
  return years
}

export function isDateBefore (
  firstDate: string,
  secondDate: string,
) {
  return dayjs(firstDate).isBefore(secondDate);
}